<template>
  <modal
    ref="modal"
    :class-name="[
      'duration-100 pt-12 overflow-hidden w-full md:w-500px 2xl:w-600px', modalHeight
    ]"
  >
    <WorkEmail
      v-if="isWorkEmailSelected"
      class="flex-grow"
      @back="clearSelectedOption"
      @complete-work-email-verification="completeAccountVerification"
    />
    <BankAccount
      v-else-if="isBankAccountSelected"
      class="flex-grow"
      @back="clearSelectedOption"
      @complete-bank-account-verification="completeAccountVerification"
      @update-bank-account-verification-options="
        setBankAccountVerificationOptions
      "
    />
    <Selection
      v-else
      :options="availableVerificationOptions"
      @select="setVerificationOption"
    />
  </modal>
</template>

<script>
export default {
  name: 'AccountVerification',
  components: {
    BankAccount: () => import('./bank-account/Index'),
    Selection: () => import('./selection/Index'),
    WorkEmail: () => import('./work-email/Index'),
  },
  data() {
    return {
      form: this.$options.basicForm([
        'verification_option',
      ], { bankAccountVerificationOptions: [] }),
    };
  },
  computed: {
    availableVerificationOptions() {
      const options = [
        'bank-account'
      ];
      if (this.isEmployed) {
        options.unshift('work-email');
      }
      return options;
    },
    isBankAccountSelected() {
      return this.verificationOption === 'bank-account';
    },
    isEmployed() {
      return this.user?.profile?.employment_status === 'employed';
    },
    modalHeight() {
      if (this.form.bankAccountVerificationOptions?.length > 3) {
        return 'h-800px sm:h-750px';
      }
      if (this.form.bankAccountVerificationOptions?.length > 2) {
        return 'h-750px sm:h-650px';
      }
      return 'h-550px';
    },
    isWorkEmailSelected() {
      return this.verificationOption === 'work-email';
    },
    verificationOption() {
      return this.form.data.verification_option.value;
    },
  },
  mounted() {
    this.$emit('mounted');
  },
  methods: {
    clearSelectedOption() {
      this.form.data.verification_option.value = null;
      this.setBankAccountVerificationOptions();
    },
    close() {
      this.$refs.modal.close();
    },
    completeAccountVerification() {
      const verificationOption = this.getFormValue('verification_option');
      this.$emit('complete-account-verification', { verificationOption });
      this.close();
    },
    open() {
      this.$refs.modal.open();
    },
    setVerificationOption(option) {
      this.form.data.verification_option.value = option;
    },
    setBankAccountVerificationOptions(options = []) {
      this.form.bankAccountVerificationOptions = options;
    },
  },
};
</script>
